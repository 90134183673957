import React from "react"
import theme from "theme"
import { Theme, Image, Text, Icon, Box, Link, Section } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
import { BsDot } from "react-icons/bs"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Home Harmony Designs</title>
        <meta
          name={"description"}
          content={"Transforming Spaces, Creating Comfort"}
        />
        <meta property={"og:title"} content={"Home | Home Harmony Designs"} />
        <meta
          property={"og:description"}
          content={"Transforming Spaces, Creating Comfort"}
        />
        <meta property={"og:image"} content={"https://tixelopride.com/img/1.jpg"} />
        <link
          rel={"shortcut icon"}
          href={"https://tixelopride.com/img/icon.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://tixelopride.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://tixelopride.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://tixelopride.com/img/icon.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://tixelopride.com/img/icon.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://tixelopride.com/img/icon.png"}
        />
      </Helmet>
      <Components.Header />
      <Section padding="0 0 0 0" quarkly-title="Hero-13">
        <Override
          slot="SectionContent"
          width="100%"
          flex-direction="row"
          max-width="100%"
        />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          justify-content="space-around"
          lg-flex-direction="column"
        >
          <Image
            src="https://tixelopride.com/img/1.jpg"
            display="block"
            width="60%"
            object-fit="cover"
            height="100%"
            lg-width="100%"
          />
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            flex-direction="column"
            align-items="flex-start"
            justify-content="flex-end"
            width="40%"
            padding="80px 80px 80px 80px"
            lg-width="100%"
            md-padding="50px 50px 80px 50px"
            sm-padding="50px 25px 80px 25px"
          >
            <Box
              min-width="10px"
              min-height="10px"
              display="flex"
              align-items="center"
              margin="0px 0px 25px 0px"
              sm-flex-direction="column"
              sm-align-items="flex-start"
            >
              <Text
                margin="0px 7px 0px 0px"
                font="normal 400 16px/1.5 --fontFamily-sans"
                color="#061e11"
                sm-margin="0px 0 7px 0px"
                letter-spacing="1px"
              >
                Let’s weave your story into your home.
              </Text>
              <Icon
                category="bs"
                icon={BsDot}
                size="24px"
                margin="0px 7px 0px 0px"
                sm-display="none"
              />
              <Text
                margin="0px 0px 0px 0px"
                font="normal 400 16px/1.5 --fontFamily-sans"
                color="#061e11"
                letter-spacing="1px"
              >
                Your dreams align with reality.
              </Text>
            </Box>
            <Text
              margin="0px 0px 20px 0px"
              font="normal 500 72px/1.2 --fontFamily-serifGeorgia"
              color="#061e11"
              lg-margin="0px 0px 35px 0px"
              md-font="normal 500 52px/1.2 --fontFamily-serifGeorgia"
              sm-font="normal 500 42px/1.2 --fontFamily-serifGeorgia"
            >
              Home Harmony Designs
            </Text>
            <Text
              margin="0px 0px 40px 0px"
              font="normal 300 20px/1.3 --fontFamily-sans"
              color="#061e11"
              letter-spacing="1px"
            >
              Welcome to a place where your dream space comes to life. At Home
              Harmony Designs, we believe in the power of transformation – not
              just for your home or office but for your everyday living. We’re
              dedicated to crafting spaces that reflect your unique personality
              and style, ensuring that every corner speaks comfort and elegance.
            </Text>
            <Link
              href="/contacts"
              color="--darkL1"
              font="normal 600 28px/1.2 --fontFamily-serifGeorgia"
            >
              Contact Us &gt;&gt;
            </Link>
          </Box>
        </Box>
      </Section>
      <Section padding="80px 0 90px 0" quarkly-title="Headline-4">
        <Override slot="SectionContent" sm-min-width="280px" />
        <Box
          min-width="100px"
          min-height="100px"
          display="flex"
          lg-flex-direction="column"
        >
          <Text
            margin="0px 0px 0px 0px"
            width="37%"
            font="normal 500 52px/1.2 --fontFamily-serifTimes"
            padding="0px 50px 0px 0px"
            lg-width="100%"
            lg-margin="0px 0px 50px 0px"
            md-padding="0px 0 0px 0px"
            md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
            sm-margin="0px 0px 35px 0px"
          >
            About Us
          </Text>
          <Box
            min-width="100px"
            min-height="100px"
            width="63%"
            display="flex"
            flex-direction="column"
            justify-content="center"
            lg-width="100%"
          >
            <Text
              margin="0px 0px 20px 0px"
              font="--lead"
              color="#727a82"
              sm-margin="0px 0px 25px 0px"
            >
              Home Harmony Designs is your go-to renovation and interior design
              service, committed to turning your vision into reality. With a
              keen eye for detail and a passion for creativity, we specialize in
              delivering customized solutions that blend beauty with
              functionality. Our team of experienced designers works tirelessly
              to ensure your space is not just aesthetically pleasing but also
              practical and welcoming.
            </Text>
          </Box>
        </Box>
      </Section>
      <Section padding="60px 0" sm-padding="40px 0" color="--dark">
        <Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
          <Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
            <Box display="flex" flex-direction="column">
              <Text as="h2" font="--headline2" margin="0 0 8px 0">
                Why Choose Us
              </Text>
            </Box>
          </Box>
          <Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
            <Box display="flex" flex-direction="row" flex-wrap="wrap">
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Personalized Approach
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    Every project is a new story, and we’re here to listen. Your
                    ideas inspire our designs, ensuring a space that’s truly
                    yours.
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Quality Craftsmanship
                  </Text>
                </Box>
                <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                  With a focus on high-quality materials and craftsmanship, we
                  guarantee a finish that’s not only beautiful but also lasting.
                </Text>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Efficient Solutions
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    Time and budget are critical. We strive to deliver efficient
                    solutions that align with your needs without compromising on
                    quality or aesthetics.
                  </Text>
                </Box>
              </Box>
              <Box
                display="flex"
                flex-direction="column"
                width="50%"
                padding="16px 16px 16px 16px"
                sm-width="100%"
              >
                <Box display="flex" flex-direction="column">
                  <Text as="h3" font="--headline3" margin="12px 0">
                    Comprehensive Services
                  </Text>
                  <Text as="p" font="--base" margin="12px 0" color="--greyD2">
                    From initial consultation to the final touches, we provide a
                    full spectrum of services to make your renovation journey
                    seamless and enjoyable.
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
